<template>
	<div class="balance">
		<h3>{{ this.CurrentAircraft.CallSign }} :: {{ this.CurrentAircraft.Type }}</h3>
		<p>
			<label>Pilot <InputWeight v-model="LocalStorage.PilotWeight" /></label>
		</p>
		<p>
			<label>Front Passenger <InputWeight v-model="Front" /></label>
		</p>
		<p>
			<label>Rear Passenger <InputWeight v-model="Rear1" /></label>
		</p>
		<p>
			<label>Rear Passenger <InputWeight v-model="Rear2" /></label>
		</p>
		<p>
			<label>Fuel <InputFuel v-model="Fuel" /></label>
		</p>
		<p>
			<label>Baggage <InputWeight v-model="Baggage" /></label>
		</p>
		<p>Gross Weight: {{ Gross }} lbs, CG: {{ CG }} inches</p>
		<SVG :parameters="CurrentAircraft.SVG" :plot="{ X: CG, Y: Gross }" />
	</div>
</template>

<script>
import InputWeight from "@/components/inputweight.vue";
import InputFuel from "@/components/inputfuel.vue";
import SVG from "@/components/svg.vue";

export default {
	name: "Balance",
	components: { InputWeight, InputFuel, SVG },
	inject: ["LocalStorage", "CurrentAircraft"],
	data: function () {
		return { Front: 0, Rear1: 0, Rear2: 0, Fuel: 0, Baggage: 0 };
	},
	computed: {
		Gross: function () {
			return +(
				this.LocalStorage.PilotWeight +
				this.Front +
				this.Rear1 +
				this.Rear2 +
				this.Fuel +
				this.Baggage +
				this.CurrentAircraft.EmptyWeight
			).toFixed(2);
		},
		TotalMoment: function () {
			return +(
				this.CurrentAircraft.EmptyWeight * this.CurrentAircraft.EmptyWeightArm +
				this.LocalStorage.PilotWeight * this.CurrentAircraft.FrontArm +
				this.Front * this.CurrentAircraft.FrontArm +
				this.Rear1 * this.CurrentAircraft.RearArm +
				this.Rear2 * this.CurrentAircraft.RearArm +
				this.Fuel * this.CurrentAircraft.FuelArm +
				this.Baggage * this.CurrentAircraft.BaggageArm
			).toFixed(2);
		},
		CG: function () {
			return +(this.TotalMoment / this.Gross).toFixed(2);
		}
	}
};
</script>

<style></style>
