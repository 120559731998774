<template>
	<Menu />
	<Settings v-if="LocalStorage.Screen == 'settings'" />
	<Balance v-if="LocalStorage.Screen == 'balance'" />
	<Wind v-if="LocalStorage.Screen == 'wind'" />
</template>

<script>
import { computed } from "vue";

import Menu from "@/components/menu.vue";
import Settings from "@/components/settings.vue";
import Balance from "@/components/balance.vue";
import Wind from "@/components/wind.vue";

export default {
	name: "App",
	components: { Menu, Settings, Balance, Wind },
	data: function () {
		return {
			LocalStorage: {
				Screen: null,
				Aircraft: [],
				CurrentAircraft: null,
				PilotWeight: 0
			}
		};
	},
	provide() {
		return {
			LocalStorage: computed(() => this.LocalStorage),
			CurrentAircraft: computed(() => this.CurrentAircraft),
			SetCurrentAircraft: this.SetCurrentAircraft
		};
	},
	created: function () {
		if (localStorage["ejw-pilot-tools"]) this.LocalStorage = JSON.parse(localStorage["ejw-pilot-tools"]);
		else
			this.LocalStorage.Aircraft = JSON.parse(
				`[{"Type":"PA28 161 Demo","EmptyWeight":1545,"EmptyWeightArm":87.96,"FrontArm":80.5,"RearArm":118.1,"FuelArm":95,"BaggageArm":142.8,"SVG":{"CGMin":83,"CGMid":88,"CGMax":93,"WeightMin":1200,"WeightMid":1950,"WeightMax":2550},"CallSign":"G-DEMO"}]`
			);
	},
	mounted: function () {},
	computed: {
		CurrentAircraft: function () {
			return this.LocalStorage.Aircraft[this.LocalStorage.CurrentAircraft] ?? null;
		}
	},
	watch: {
		LocalStorage: {
			handler: function (v) {
				localStorage["ejw-pilot-tools"] = JSON.stringify(this.LocalStorage);
			},
			deep: true
		}
	},
	methods: {
		SetCurrentAircraft: function (v) {
			if (!isNaN(v)) this.LocalStorage.CurrentAircraft = v;
			else if (typeof v === "object") {
				this.LocalStorage.CurrentAircraft = this.LocalStorage.Aircraft.indexOf(v);
			} else this.LocalStorage.CurrentAircraft = null;
		}
	}
};
</script>

<style>
#app {
}
</style>
