<template>
	<input type="number" v-model.number="InputValue" />
	<button @click="UnitIndex++">{{ Units[UnitIndex].Label }}</button>
</template>

<script>
export default {
	name: "InputLength",
	props: {
		modelValue: {
			type: Number,
			required: true
		}
	},
	data: function () {
		return {
			InputValue: this.modelValue,
			Units: [
				{
					Label: "Inches",
					Convert: function (normalised) {
						return normalised;
					},
					Normalise: function (value) {
						return value;
					}
				},
				{
					Label: "cm",
					Convert: function (normalised) {
						return +(normalised * 2.54).toFixed(2);
					},
					Normalise: function (value) {
						return +(value / 2.54).toFixed(2);
					}
				}
			],
			UnitIndex: 0,
			UpdateTimer: null
		};
	},
	mounted: function () {
		console.log("mounted");
	},
	watch: {
		modelValue: function (v) {
			console.log(v);

			this.InputValue = this.Units[this.UnitIndex].Convert(v);
		},
		UnitIndex: {
			handler: async function (v, old) {
				if (v >= this.Units.length) {
					this.UnitIndex = 0;
					return;
				}
				if (old >= this.Units.length) old = this.Units.length - 1;
				console.log(v, old);
				this.InputValue = this.Units[this.UnitIndex].Convert(this.Units[old].Normalise(this.InputValue));
			},
			immediate: false
		},
		InputValue: {
			handler: function (v) {
				//console.log("emitting", this.Units[this.UnitIndex].Normalise(this.InputValue));
				//this.$emit("update:modelValue", this.Units[this.UnitIndex].Normalise(this.InputValue));
				if (this.UpdateTimer) window.clearTimeout(this.UpdateTimer);
				this.UpdateTimer = window.setTimeout(() => {
					this.$emit("update:modelValue", this.Units[this.UnitIndex].Normalise(this.InputValue));
				}, 1000);
			},
			immediate: true
		}
	},
	computed: {},
	methods: {}
};
</script>

<style></style>
