<template>
	<div class="wind">
		<div class="inputs">
			<p>
				<i class="fa-thin fa-windsock fa-fw"></i>
				<span>Wind Direction</span>
				<input type="number" max="359" min="0" v-model.number="WindDirection" />°
				<input type="range" max="359" min="0" step="5" v-model.number="WindDirection" />
			</p>
			<p>
				<i class="fa-thin fa-wind fa-fw"></i>
				<span>Wind Velocity</span>
				<input type="number" max="80" min="0" v-model.number="WindVelocity" />
				<input type="range" max="80" min="0" step="5" v-model.number="WindVelocity" />
			</p>
			<p>
				<i class="fa-thin fa-compass fa-fw"></i>
				<span>Track</span>
				<input type="number" max="359" min="0" v-model.number="Track" />°
				<input type="range" max="359" min="0" v-model.number="Track" />
			</p>
			<p>
				<i class="fa-thin fa-ruler-horizontal fa-fw"></i>
				<span>Distance</span>
				<input type="number" min="0" max="1000" v-model.number="Distance" />
				<input type="range" max="200" min="0" v-model.number="Distance" />
			</p>
			<p>
				<i class="fa-thin fa-plane-engines fa-fw"></i>
				<span>TAS</span>
				<input type="number" max="220" min="40" v-model.number="TAS" />
				<input type="range" max="220" min="40" v-model.number="TAS" />
			</p>
			<p>
				<i class="fa-thin fa-location-arrow fa-fw"></i>
				<span>Heading {{ Calc(this.Track).Heading }}°</span>
			</p>
			<p>
				<i class="fa-thin fa-gauge fa-fw"></i>
				<span>Ground Speed {{ Calc(this.Track).GroundSpeed }}</span>
			</p>
			<p>
				<i class="fa-thin fa-clock fa-fw"></i>
				<span>Time {{ Calc(this.Track).Time }} minutes</span>
			</p>
		</div>
		<div class="corrections">
			<p>{{ Calc(360 - 45).WindCorrectionAngle }}°<br />{{ Calc(360 - 45).Deviation }} kts</p>
			<p>{{ Calc(0).WindCorrectionAngle }}°<br />{{ Calc(0).Deviation }} kts</p>
			<p>{{ Calc(45).WindCorrectionAngle }}°<br />{{ Calc(45).Deviation }} kts</p>
			<p>{{ Calc(270).WindCorrectionAngle }}°<br />{{ Calc(270).Deviation }} kts</p>
			<p><i class="fa-regular fa-up-down-left-right"></i></p>
			<p>{{ Calc(90).WindCorrectionAngle }}°<br />{{ Calc(90).Deviation }} kts</p>
			<p>{{ Calc(180 + 45).WindCorrectionAngle }}°<br />{{ Calc(180 + 45).Deviation }} kts</p>
			<p>{{ Calc(180).WindCorrectionAngle }}°<br />{{ Calc(180).Deviation }} kts</p>
			<p>{{ Calc(180 - 45).WindCorrectionAngle }}°<br />{{ Calc(180 - 45).Deviation }} kts</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "Wind",
	inject: ["LocalStorage"],
	data: function () {
		return {
			WindDirection: 0,
			WindVelocity: 0,
			Track: 0,
			Distance: 0,
			TAS: 100
		};
	},
	computed: {},
	methods: {
		Calc: function (track) {
			let _crs = (Math.PI / 180) * track;
			let _wd = (Math.PI / 180) * this.WindDirection;
			let _swc = (this.WindVelocity / this.TAS) * Math.sin(_wd - _crs);
			let _hd = _crs + Math.asin(_swc);
			if (_hd < 0) _hd = _hd + 2 * Math.PI;
			if (_hd > 2 * Math.PI) _hd = _hd - 2 * Math.PI;
			var _wca = Math.round(
				(180 / Math.PI) *
					(Math.atan2(
						this.WindVelocity * Math.sin(_hd - _wd),
						this.TAS - this.WindVelocity * Math.cos(_hd - _wd)
					) *
						-1)
			);
			let _gs = Math.round(
				this.TAS * Math.sqrt(1 - Math.pow(_swc, 2)) - this.WindVelocity * Math.cos(_wd - _crs)
			);
			let _dev = _gs - this.TAS;

			return {
				Heading: Math.round((180 / Math.PI) * _hd),
				GroundSpeed: _gs,
				WindCorrectionAngle: `${_wca > 0 ? "+" : ""}${_wca}`,
				Time: +((this.Distance / _gs) * 60).toFixed(2),
				Deviation: `${_dev > 0 ? "+" : ""}${_dev}`
			};
		}
	}
};
</script>

<style>
div.wind div.corrections {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}
</style>
