<template>
	<div class="menu">
		<button :disabled="!CurrentAircraft" @click="LocalStorage.Screen = 'balance'">Weight and Balance</button>
		<button @click="LocalStorage.Screen = 'wind'">Wizz Wheel</button>
		<button @click="LocalStorage.Screen = 'settings'">Settings</button>
	</div>
</template>

<script>
export default {
	name: "Menu",
	inject: ["LocalStorage", "CurrentAircraft"],
	data: function () {
		return {};
	}
};
</script>

<style></style>
