<template>
	<div class="settings">
		<div class="aircraft">
			<ul>
				<li v-for="(a, i) in LocalStorage.Aircraft" :key="a" @click="SetCurrentAircraft(i)">
					{{ a.Type }}
				</li>
			</ul>
			<button @click="HandleNewClick">New</button>
			<button @click="HandleDuplicateClick">Duplicate</button>
			<button @click="HandleDeleteClick">Delete</button>
		</div>
		<div class="current" v-if="CurrentAircraft">
			<p>
				<label>Type: <input type="text" v-model="CurrentAircraft.Type" /></label>
			</p>
			<p>
				<label>CallSign: <input type="text" v-model="CurrentAircraft.CallSign" /></label>
			</p>
			<p>
				<label>Empty Weight <InputWeight v-model="CurrentAircraft.EmptyWeight" /> </label>
			</p>
			<p>
				<label>
					Empty Weight Arm
					<InputLength v-model="CurrentAircraft.EmptyWeightArm" />
				</label>
			</p>
			<p>
				<label>Front Arm <InputLength v-model="CurrentAircraft.FrontArm" /></label>
			</p>
			<p>
				<label>Rear Arm <InputLength v-model="CurrentAircraft.RearArm" /></label>
			</p>
			<p>
				<label>Fuel Arm <InputLength v-model="CurrentAircraft.FuelArm" /></label>
			</p>
			<p>
				<label>Baggage Arm <InputLength v-model="CurrentAircraft.BaggageArm" /></label>
			</p>
			<p>Chart CG Min <input type="number" v-model.number="CurrentAircraft.SVG.CGMin" /></p>
			<p>Chart CG Mid <input type="number" v-model.number="CurrentAircraft.SVG.CGMid" /></p>
			<p>Chart CG Max <input type="number" v-model.number="CurrentAircraft.SVG.CGMax" /></p>
			<p>Chart Weight Min <input type="number" v-model.number="CurrentAircraft.SVG.WeightMin" /></p>
			<p>Chart Weight Mid <input type="number" v-model.number="CurrentAircraft.SVG.WeightMid" /></p>
			<p>Chart Weight Max <input type="number" v-model.number="CurrentAircraft.SVG.WeightMax" /></p>
			<SVG :parameters="CurrentAircraft.SVG" />
		</div>
	</div>
</template>

<script>
import InputWeight from "@/components/inputweight.vue";
import InputLength from "@/components/inputlength.vue";
import SVG from "@/components/svg.vue";

export default {
	name: "Settings",
	components: { InputWeight, InputLength, SVG },
	inject: ["LocalStorage", "CurrentAircraft", "SetCurrentAircraft"],
	data: function () {
		return {};
	},
	methods: {
		HandleNewClick: function () {
			this.LocalStorage.Aircraft.push({
				Type: "New Type",
				CallSign: "G-NEW",
				EmptyWeight: 1000,
				EmptyWeightArm: 100,
				FrontArm: 100,
				RearArm: 100,
				FuelArm: 100,
				BaggageArm: 100,
				SVG: {
					CGMin: 80,
					CGMid: 88,
					CGMax: 95,
					WeightMin: 1200,
					WeightMid: 1950,
					WeightMax: 2500
				}
			});
			this.SetCurrentAircraft(this.LocalStorage.Aircraft.length - 1);
		},
		HandleDuplicateClick: function () {
			this.LocalStorage.Aircraft.push({
				...this.CurrentAircraft,
				CallSign: `Copy of ${this.CurrentAircraft.CallSign}`
			});
			this.SetCurrentAircraft(this.LocalStorage.Aircraft.length - 1);
		},
		HandleDeleteClick: function () {
			this.LocalStorage.Aircraft.splice(this.LocalStorage.CurrentAircraft, 1);
			this.SetCurrentAircraft(0);
		}
	}
};
</script>

<style></style>
