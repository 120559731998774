<template>
	<input type="number" v-model.number="InputValue" />
	<button @click="UnitIndex++">{{ Units[UnitIndex].Label }}</button>
</template>

<script>
export default {
	name: "InputWeight",
	props: {
		modelValue: {
			type: Number,
			required: true
		}
	},
	data: function () {
		return {
			InputValue: this.modelValue,
			Units: [
				{
					Label: "Pounds",
					Convert: function (normalised) {
						return normalised;
					},
					Normalise: function (value) {
						return value;
					}
				},
				{
					Label: "Kg",
					Convert: function (normalised) {
						return +(normalised * 0.453592369999748).toFixed(2);
					},
					Normalise: function (value) {
						return +(value * 2.20462262185).toFixed(2);
					}
				},

				{
					Label: "Stone",
					Convert: function (normalised) {
						return parseFloat(`${Math.floor(normalised / 14)}.${normalised % 14}`);
					},
					Normalise: function (value) {
						return Math.floor(value) * 14 + parseInt(`${value}.0`.split(".")[1]);
					}
				}
			],
			UnitIndex: 0,
			UpdateTimer: null
		};
	},
	mounted: function () {
		console.log("mounted");
	},
	watch: {
		modelValue: function (v) {
			console.log(v);

			this.InputValue = this.Units[this.UnitIndex].Convert(v);
		},
		UnitIndex: {
			handler: async function (v, old) {
				if (v >= this.Units.length) {
					this.UnitIndex = 0;
					return;
				}
				if (old >= this.Units.length) old = this.Units.length - 1;
				console.log(v, old);
				this.InputValue = this.Units[this.UnitIndex].Convert(this.Units[old].Normalise(this.InputValue));
			},
			immediate: false
		},
		InputValue: {
			handler: function (v) {
				if (
					this.Units[this.UnitIndex].Label == "Stone" &&
					parseInt(`${this.InputValue}.0`.split(".")[1]) > 13
				) {
					this.InputValue = parseFloat(`${Math.floor(this.InputValue)}.13`);
				}
				if (this.UpdateTimer) window.clearTimeout(this.UpdateTimer);
				this.UpdateTimer = window.setTimeout(() => {
					this.$emit("update:modelValue", this.Units[this.UnitIndex].Normalise(this.InputValue));
				}, 1000);
			},
			immediate: true
		}
	},
	computed: {},
	methods: {}
};
</script>

<style></style>
