<template>
	<svg :width="Width" :height="Height">
		<polygon
			:points="`0,0 0,${Height} ${Width},${Height} ${Width},0`"
			style="fill: #eee; stroke: #aaa; stroke-width: 1"
		/>

		<polygon :points="Outline" style="fill: #fff; stroke: #888; stroke-width: 1" />
		<template v-for="x in TextLabels.X" :key="x">
			<text :x="InchToX(x)" :y="Height - Margin / 5" fill="#000" text-anchor="middle" style="font-size: 0.7em">
				{{ x }}
			</text>
		</template>
		<template v-for="y in TextLabels.Y" :key="y">
			<text
				:y="PoundsToY(y)"
				:x="Width - Margin / 5"
				fill="#000"
				text-anchor="end"
				dominant-baseline="middle"
				style="font-size: 0.7em"
			>
				{{ y }}
			</text>
		</template>
		<circle v-if="plot" :cx="Plot.X" :cy="Plot.Y" :r="Width / 50" stroke="black" stroke-width="1" fill="red" />
	</svg>
</template>

<script>
export default {
	name: "SVG",
	inject: ["LocalStorage"],
	props: {
		parameters: {
			type: Object,
			required: true
		},
		plot: Object
	},
	data: function () {
		return {
			Width: 300,
			Height: 300,
			Margin: 35
		};
	},
	mounted: function () {
		console.log(this.InchToX(88));
	},
	computed: {
		Outline: function () {
			return (
				`${this.InchToX(this.parameters.CGMin)},${this.PoundsToY(this.parameters.WeightMin)} ` +
				`${this.InchToX(this.parameters.CGMin)},${this.PoundsToY(this.parameters.WeightMid)} ` +
				`${this.InchToX(this.parameters.CGMid)},${this.PoundsToY(this.parameters.WeightMax)} ` +
				`${this.InchToX(this.parameters.CGMax)},${this.PoundsToY(this.parameters.WeightMax)} ` +
				`${this.InchToX(this.parameters.CGMax)},${this.PoundsToY(this.parameters.WeightMin)} `
			);
		},
		TextLabels: function () {
			let _x = [];
			for (let i = this.parameters.CGMin; i <= this.parameters.CGMax; i++) _x.push(i);
			let _y = [];
			for (let i = Math.ceil(this.parameters.WeightMin / 100) * 100; i <= this.parameters.WeightMax; i += 100)
				_y.push(i);
			return {
				X: _x,
				Y: _y
			};
		},
		Plot: function () {
			let x = this.InchToX(this.plot.X);
			if (x < 0) x = 0;
			if (x > this.Width) x = this.Width;
			let y = this.PoundsToY(this.plot.Y);
			if (y < 0) x = 0;
			if (y > this.Height) x = this.Height;
			return { X: x, Y: y };
		}
	},
	methods: {
		InchToX: function (inch) {
			let xRange = this.Width - 2 * this.Margin;
			let inchRange = this.parameters.CGMax - this.parameters.CGMin;
			return ((inch - this.parameters.CGMin) * xRange) / inchRange + this.Margin;
		},
		PoundsToY: function (pounds) {
			let yRange = this.Height - 2 * this.Margin;
			let poundRange = this.parameters.WeightMax - this.parameters.WeightMin;
			return this.Height - (((pounds - this.parameters.WeightMin) * yRange) / poundRange + this.Margin);
		}
	}
};
</script>

<style></style>
